*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  width: 100%;
	vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}
body{
  word-wrap: break-word;
  color: #333;
}
/* デバッグ用 */
/* body * {
  outline: 1px dotted rgba(255,0,0,0.2) !important;
}
body * * {
  outline: 1px dotted rgba(0,128,0,0.2) !important;
}
body * * * {
  outline: 1px dotted rgba(255,165,0,0.2) !important;
}
body * * * * {
  outline: 1px dotted rgba(0,0,255,0.2) !important;
} */