.l-wrap{
  // min-width: 1280px;
}
.l-main {
  position: relative;
  @include mq(o-sp) {
    min-width: 1280px;
    padding-top: 112px;
    // max-width: 1600px;
  }
  @include mq(u-sp) {
    padding-top: 67px;
  }
}
.l-mainWrapper{
  width: 1280px;
  margin: 0 auto;
  // padding: 100px 0px;
  // margin-bottom: 100px;
}
