.c-static__title__wrap {
  background-color: $colorMain;
  padding-bottom: 50px;
  @include mq(u-sp) {
    padding: 17px 0 46px;
  }
}
.c-static__title {
  @extend .u-fs-46-28,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    line-height: 35px;
    letter-spacing: 1.22px;
  }
}
.c-static__title__translation {
  @extend .u-fs-18-14,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 7px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    line-height: 19px;
    letter-spacing: 0.78px;
  }
}
.c-static {
  @include mq(o-sp) {
    width: 960px;
    margin: 0 auto;
    padding: 140px 0 280px;
  }
  @include mq(u-sp) {
    padding: 46px 20px 160px;
  }
}
.c-static__text {
  @extend .u-fs-14, .u-ff-NotoR;
  line-height: 25px;
  letter-spacing: 1px;
}
.c-static__text--sub {
  @extend .u-fs-14, .u-ff-NotoR;
  line-height: 25px;
  letter-spacing: 1px;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin: 20px 0 86px;
  }
  @include mq(u-sp) {
    margin: 8px 0 39px;
  }
}
.c-static__list {

}
.c-static__listItem {
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-top: 36px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 50px;
    }
  }
}
.c-static__listItem__heading {
  @extend .u-fs-21-18, .u-ff-NotoR;
  color: $colorMain;
  @include mq(o-sp) {
    line-height: 40px;
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 0.40px//0.56px;
  }
}
.c-static__listItem__text {
  @extend .u-fs-14, .u-ff-NotoR;
  border-top: 1px dotted $colorMain;
  letter-spacing: 1px;
  @include mq(o-sp) {
    padding-top: 11px;
  }
  @include mq(u-sp) {
    margin-top: 11px;
    padding-top: 7px;
  }
}
.c-static__item {
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-top: 36px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.c-static__item__heading {
  @extend .u-fs-21-18, .u-ff-NotoR;
  color: $colorMain;
  @include mq(o-sp) {
    line-height: 40px;
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 0.56px;
  }
  span {
    @extend .u-fs-12, .u-ff-NotoR;
    color: $colorThinGray;
  }
}
.c-static__item__text {
  @extend .u-fs-14, .u-ff-NotoR;
  letter-spacing: 1px;
  span {
    display: inline-block;
    width: 200px;
  }
  p {
    @extend .u-mt-4;
  }
  margin-top: 11px;
}
