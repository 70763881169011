.p-skeletonDiagnosis {
  @include mq(o-sp) {
    width: 1080px;
    margin: 210px auto 0;
    padding-bottom: 250px;
  }
  @include mq(u-sp) {
    padding-bottom: 150px
  }
}
// kv
.p-skeletonDiagnosis-kv {
  background-color: $colorStyle;
}
.p-skeletonDiagnosis-kv__wrap {
  @include mq(o-sp) {
    position: relative;
    max-width: 1600px;
    min-width: 1280px;
    width: 100%;
    margin: 0 auto;
    height: 540px;
  }
}
.p-skeletonDiagnosis-kv__img {
  @include mq(o-sp) {
    position: absolute;
    top: 0;
    right: 0;
    width: 1000px;
  }
}
.p-skeletonDiagnosis-kv__text__wrap {
  @extend .u-ff-NotoR;
  @include mq(o-sp) {
    position: absolute;
    top: 40%;
    left: 80px;
    transform: translateY(-50%);
    z-index: 1;
    color: $colorWhite;
    text-shadow: 0 2px 7px rgba($color: #515151, $alpha: .3);
  }
  @include mq(u-sp) {
    color: $colorBlack;
    padding: 42px 14px 55px;
  }
}
.p-skeletonDiagnosis-kv__heading {
  @extend .u-fs-46-28, .u-ff-NotoR;
  @include mq(o-sp) {
    padding: 10px 9px;
    line-height: 55px;
    letter-spacing: 4px;
  }
  @include mq(u-sp) {
    background-color: $colorWhite;
    display: inline-block;
    padding: 3px 6px 4px 6px;
    line-height: 35px;
    letter-spacing: 1.22px;
  }
}
.p-skeletonDiagnosis-kv__text {
  @extend .u-fs-17-14, .u-ff-NotoR;
  @include mq(o-sp) {
    padding: 10px 11px;
    line-height: 27px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    padding: 1px 5px 4px 7px;
    line-height: 22px;
    letter-spacing: 1px;
    display: inline-block;
    background-color: $colorWhite;
  }
}



// description
.p-skeletonDiagnosis-description {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
  }
  @include mq(u-sp) {
    margin-top: 73px;
    padding: 0 20px;
  }
}
.p-skeletonDiagnosis-description__text__wrap {
  @include mq(o-sp) {
    width: 420px;
    padding-top: 74px;
  }
}
.p-skeletonDiagnosis-description__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 34px;
  }
}
.p-skeletonDiagnosis-description__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 26px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
    line-height: 24px;
    letter-spacing: 1.4px;
  }
}
.p-skeletonDiagnosis-description__img {
  @include mq(o-sp) {
    width: 600px;
  }
  @include mq(u-sp) {
    margin-top: 26px;
  }
}

// type
.p-skeletonDiagnosis-type {
  @include mq(o-sp) {
    margin-top: 93px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-skeletonDiagnosis-type__heading {
  @extend .u-fs-21-18, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: 0.5;
  }
}
.p-skeletonDiagnosis-type__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    width: 700px;
    margin: 17px auto 0;
    line-height: 25px;
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    margin-top: 16px;
    line-height: 24px;
    letter-spacing: 1.4px;
  }
}
.p-skeletonDiagnosis-type__content {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 126px;
    &:nth-child(n+4) {
      margin-top: 153px;
    }
  }
  @include mq(u-sp) {
    margin-top: 40px;
  }
}
.p-skeletonDiagnosis-type__content__text__wrap {
  @include mq(o-sp) {
    width: 499px;
    padding-top: 77px;
  }
}
.p-skeletonDiagnosis-type__content__heading {
  @extend .u-fs-21-16, .u-ff-NotoR;
  @include mq(o-sp) {
    position: relative;
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    line-height: 23px;
    letter-spacing: 0.76px;
    text-align: center;
  }
}
.p-skeletonDiagnosis-type__content__text {
  @extend .u-fs-14, .u-ff-NotoR;
  line-height: 25px;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 18px;
  }
  @include mq(u-sp) {
    margin-top: 14px;
  }
}
.p-skeletonDiagnosis-type__content__imgs__wrap {
  @include mq(u-sp) {
    margin-top: 22px;
  }
}
.p-skeletonDiagnosis-type__content__imgs {
  display: flex;
  justify-content: space-between;
  @include mq(o-sp) {
    width: 499px;
  }
}
.p-skeletonDiagnosis-type__content__img__wrap {
  @include mq(o-sp) {
    position: relative;
  }
  @include mq(u-sp) {
    width: 48%;
  }
}
.p-skeletonDiagnosis-type__content__img__tag--good {
  @extend .u-fs-12-14, .u-ff-NotoR;
  color: $colorTagGood;
  border: 1px solid $colorTagGood;
  text-align: center;
  @include mq(o-sp) {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 51px;
    padding: 3px 0;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    padding: 1px 0;
  }
}
.p-skeletonDiagnosis-type__content__img__tag--bad {
  @extend .u-fs-12-14, .u-ff-NotoR;
  color: $colorTagBad;
  border: 1px solid $colorTagBad;
  text-align: center;
  @include mq(o-sp) {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 51px;
    padding: 3px 0;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    padding: 1px 0;
  }
}
.p-skeletonDiagnosis-type__content__img {
  position: relative;
  @include mq(o-sp) {
    width: 237px;
    height: 377px;
  }
}
.p-skeletonDiagnosis-type__content__img__type {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: .8s;
  &.is-active {
    opacity: 1;
  }
}
.p-skeletonDiagnosis-type__content__img__btn__wrap {
  @include mq(o-sp) {
    display: flex;
    margin-top: 23px;
    border: 1px solid #E9E7E7;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    border: 1px solid #E9E7E7;
  }
}
.p-skeletonDiagnosis-type__content__img__btn {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorBtn;
  text-align: center;
  user-select: none;
  cursor: pointer;
  &.is-active {
    color: $colorWhite;
    background-color: $colorBtn;
  }
  @include mq(o-sp) {
    width: calc(100% / 3);
    padding: 5px 0;
    &:not(:first-child) {
      border-left: 1px solid #E9E7E7;
    }
  }
  @include mq(u-sp) {
    width: 100%;
    padding: 10.5px 0;
    &:not(:first-child) {
      border-top: 1px solid #E9E7E7;
    }
  }
}

// merit
.p-skeletonDiagnosis-merit {
  @include mq(o-sp) {
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-skeletonDiagnosis-merit__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-skeletonDiagnosis-merit__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
  }
}
.p-skeletonDiagnosis-merit__contents {
  @include mq(o-sp) {
    margin-top: 41px;
  }
  @include mq(u-sp) {
    margin-top: 35px;
  }
}
.p-skeletonDiagnosis-merit__content__wrap {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 49px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.p-skeletonDiagnosis-merit__content {
  @include mq(o-sp) {
    width: 510px;
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.p-skeletonDiagnosis-merit__content__heading {
  @extend .u-fs-21-18,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    line-height: 18px;
    letter-spacing: 0.86px;
  }
}
.p-skeletonDiagnosis-merit__content__text {
  @extend .u-fs-14, .u-ff-NotoR;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 14px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
  }
}

// flow
.p-skeletonDiagnosis-flow {
  @include mq(o-sp) {
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-skeletonDiagnosis-flow__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1.47px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: 1px;
  }
}
.p-skeletonDiagnosis-flow__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  margin-top: 5px;
  color: $colorThinGray;
  text-align: center;
  letter-spacing: 1px;
}
.p-skeletonDiagnosis-flow__blocks {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
  }
  @include mq(u-sp) {
    margin-top: 36px;
  }
}
.p-skeletonDiagnosis-flow__block {
  color: $colorWhite;
  @include mq(o-sp) {
    width: 247px;
    padding: 39px 26px 24px;
  }
  @include mq(u-sp) {
    padding: 23px 19px 28px;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}
.p-skeletonDiagnosis-flow__block__step {
  @extend .u-fs-20-18, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 0.6px;
  }
}
.p-skeletonDiagnosis-flow__block__heading {
  @extend .u-fs-24-21, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    margin-top: 7px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}
.p-skeletonDiagnosis-flow__block__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 17px;
    padding-left: 5px;
    letter-spacing: 1.08px;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    padding: 0 10px;
    letter-spacing: 1px;
  }
}

// menu
.p-skeletonDiagnosis-menu {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-skeletonDiagnosis-menu__heading__wrap {
  @include mq(o-sp) {
    width: 322px;
  }
}
.p-skeletonDiagnosis-menu__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 2.5px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-skeletonDiagnosis-menu__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 6px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    text-align: center;
    margin-top: 5px;
    letter-spacing: 1px;
  }
}
.p-skeletonDiagnosis-menu__text {
  @extend .u-fs-14, .u-ff-NotoR;
  line-height: 24px;
  letter-spacing: 1.4px;
  @include mq(o-sp) {
    margin-top: 17px;
  }
  @include mq(u-sp) {
    margin-top: 30px;
  }
}
.p-skeletonDiagnosis-menu__btn {
  @include mq(o-sp) {
    width: 250px;
    margin-top: 26px;
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 30px auto 0;
  }
}
.p-skeletonDiagnosis-menu__contents {
  @include mq(u-sp) {
    margin-top: 26px;
  }
}
.p-skeletonDiagnosis-menu__content {
  @extend .u-ff-NotoR;
  background-color: $colorBg;
  &:not(:first-child) {
    margin-top: 10px;
  }
  @include mq(o-sp) {
    width: 680px;
    padding: 28px 32px 26px;
  }
  @include mq(u-sp) {
    padding: 26px 20px 20px;
  }
}
.p-skeletonDiagnosis-menu__content__heading {
  @extend .u-fs-16, .u-ff-NotoR;
  line-height: 23px;
  letter-spacing: 1.6px;
}
.p-skeletonDiagnosis-menu__content__item {
  @extend .u-fs-14, .u-ff-NotoR;
  display: flex;
  line-height: 20px;
  @include mq(o-sp) {
    margin-top: 17px;
    &:nth-child(n+3) {
      margin-top: 18px;
    }
  }
  @include mq(u-sp) {
    margin-top: 21px;
    &:nth-child(n+3) {
      margin-top: 13px;
    }
  }
}
.p-skeletonDiagnosis-menu__content__item__heading {
  color: $colorMain;
  @include mq(o-sp) {
    width: 102px;
  }
  @include mq(u-sp) {
    flex-shrink: 0;
    width: 50px;
  }
}
.p-skeletonDiagnosis-menu__content__item__text {
  @include mq(u-sp) {
    word-break: break-all;
  }
}
.p-skeletonDiagnosis-menu__content__list {
  // text-indent: -24px;
  // margin-left:  6px;

  @include mq(o-sp) {
    margin-top: 24px;
  }
  @include mq(u-sp) {
    margin-top: 14px;
  }
}
.p-skeletonDiagnosis-menu__content__listItem {
  @extend .u-fs-12, .u-ff-NotoR;
  padding-left: 1em;
  text-indent: -1em;
  line-height: 18px;
  color: $colorThinGray;
  &::before {
    content: "・";
    color: $colorMain;
  }
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 9px;
    }
  }
}
