.p-personalColor {
  @include mq(o-sp) {
    width: 1080px;
    margin: 210px auto 0;
    padding-bottom: 250px;
  }
  @include mq(u-sp) {
    padding-bottom: 150px
  }
}
// kv
.p-personalColor-kv {
  background-color: $colorPersonal;
}
.p-personalColor-kv__wrap {
  @include mq(o-sp) {
    position: relative;
    max-width: 1600px;
    min-width: 1280px;
    width: 100%;
    margin: 0 auto;
    height: 540px;
  }
}
.p-personalColor-kv__img {
  @include mq(o-sp) {
    position: absolute;
    top: 0;
    right: 0;
    width: 1000px;
  }
}
.p-personalColor-kv__text__wrap {
  @extend .u-ff-NotoR;
  @include mq(o-sp) {
    position: absolute;
    top: 40%;
    left: 80px;
    transform: translateY(-50%);
    z-index: 1;
    color: $colorWhite;
    text-shadow: 0 2px 7px rgba($color: #515151, $alpha: .3);
  }
  @include mq(u-sp) {
    color: $colorBlack;
    padding: 42px 14px 54px;
  }
}
.p-personalColor-kv__heading {
  @extend .u-fs-46-28, .u-ff-NotoR;
  @include mq(o-sp) {
    padding: 10px;
    line-height: 67px;
    letter-spacing: 4px;
  }
  @include mq(u-sp) {
    background-color: $colorWhite;
    padding: 3px 6px 4px 6px;
    line-height: 35px;
  }
}
.p-personalColor-kv__text {
  @extend .u-fs-17-14, .u-ff-NotoR;
  @include mq(o-sp) {
    padding: 10px;
    line-height: 27px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    display: inline-block;
    background-color: $colorWhite;
    padding: 0 6px 6px 6px;
    line-height: 22px;
    letter-spacing: 1px;
  }
}

// description
.p-personalColor-description {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 150px;
  }
  @include mq(u-sp) {
    margin-top: 73px;
    padding: 0 20px;
  }
}
.p-personalColor-description__text__wrap {
  @include mq(o-sp) {
    width: 420px;
    padding-top: 74px;
  }
}
.p-personalColor-description__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    text-align: center;
    letter-spacing: 0.5px;
  }
}
.p-personalColor-description__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 26px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
    line-height: 24px;
    letter-spacing: 1.4px;
  }
}
.p-personalColor-description__img {
  @include mq(o-sp) {
    width: 600px;
  }
  @include mq(u-sp) {
    margin-top: 26px;
  }
}


// merit
.p-personalColor-merit {
  @include mq(o-sp) {
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-personalColor-merit__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-personalColor-merit__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
  }
}
.p-personalColor-merit__contents {
  @include mq(o-sp) {
    margin-top: 41px;
  }
  @include mq(u-sp) {
    margin-top: 35px;
  }
}
.p-personalColor-merit__content__wrap {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 49px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.p-personalColor-merit__content {
  @include mq(o-sp) {
    width: 510px;
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.p-personalColor-merit__content__heading {
  @extend .u-fs-21-18,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    line-height: 18px;
    letter-spacing: 0.86px;
  }
}
.p-personalColor-merit__content__text {
  @extend .u-fs-14, .u-ff-NotoR;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 14px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
  }
}

// flow
.p-personalColor-flow {
  @include mq(o-sp) {
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-personalColor-flow__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1.47px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: 1px;
  }
}
.p-personalColor-flow__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  margin-top: 5px;
  color: $colorThinGray;
  text-align: center;
  letter-spacing: 1px;
}
.p-personalColor-flow__blocks {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
  }
  @include mq(u-sp) {
    margin-top: 36px;
  }
}
.p-personalColor-flow__block {
  color: $colorWhite;
  @include mq(o-sp) {
    width: 247px;
    padding: 39px 26px 24px;
  }
  @include mq(u-sp) {
    padding: 23px 19px 28px;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}
.p-personalColor-flow__block__step {
  @extend .u-fs-20-18, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 0.6px;
  }
}
.p-personalColor-flow__block__heading {
  @extend .u-fs-24-21, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    margin-top: 7px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}
.p-personalColor-flow__block__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 17px;
    padding-left: 5px;
    letter-spacing: 1.08px;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    padding: 0 10px;
    letter-spacing: 1px;
  }
}

// menu
.p-personalColor-menu {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-personalColor-menu__heading__wrap {
  @include mq(o-sp) {
    width: 322px;
  }
}
.p-personalColor-menu__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 2.5px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-personalColor-menu__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 6px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    text-align: center;
    margin-top: 5px;
    letter-spacing: 1px;
  }
}
.p-personalColor-menu__text {
  @extend .u-fs-14, .u-ff-NotoR;
  line-height: 24px;
  letter-spacing: 1.4px;
  @include mq(o-sp) {
    margin-top: 17px;
  }
  @include mq(u-sp) {
    margin-top: 30px;
  }
}
.p-personalColor-menu__btn {
  >a {
    background-color: $colorPersonal;
  }
  @include mq(o-sp) {
    width: 250px;
    margin-top: 26px;
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 30px auto 0;
  }
}
.p-personalColor-menu__contents {
  @include mq(u-sp) {
    margin-top: 26px;
  }
}
.p-personalColor-menu__content {
  @extend .u-ff-NotoR;
  background-color: $colorBg;
  &:not(:first-child) {
    margin-top: 10px;
  }
  @include mq(o-sp) {
    width: 680px;
    padding: 28px 32px 26px;
  }
  @include mq(u-sp) {
    padding: 26px 20px 20px;
  }
}
.p-personalColor-menu__content__heading {
  @extend .u-fs-16, .u-ff-NotoR;
  line-height: 23px;
  letter-spacing: 1.6px;
}
.p-personalColor-menu__content__item {
  @extend .u-fs-14, .u-ff-NotoR;
  display: flex;
  line-height: 20px;
  @include mq(o-sp) {
    margin-top: 17px;
    &:nth-child(n+3) {
      margin-top: 18px;
    }
  }
  @include mq(u-sp) {
    margin-top: 21px;
    &:nth-child(n+3) {
      margin-top: 13px;
    }
  }
}
.p-personalColor-menu__content__item__heading {
  color: $colorMain;
  @include mq(o-sp) {
    width: 102px;
  }
  @include mq(u-sp) {
    flex-shrink: 0;
    width: 50px;
  }
}
.p-personalColor-menu__content__item__text {
  @include mq(u-sp) {
    word-break: break-all;
  }
}
.p-personalColor-menu__content__list {
  // text-indent: -24px;
  // margin-left:  6px;
  @include mq(o-sp) {
    margin-top: 24px;
  }
  @include mq(u-sp) {
    margin-top: 14px;
  }
}
.p-personalColor-menu__content__listItem {
  @extend .u-fs-12, .u-ff-NotoR;
  padding-left: 1em;
  text-indent: -1em;
  line-height: 18px;
  color: $colorThinGray;
  &::before {
    content: "・";
    color: $colorMain;
  }
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 9px;
    }
  }
}
