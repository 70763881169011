/* 
=================サンプル=================*/

// home
.p-home{
  @include mq(o-sp) {
    padding-bottom: 250px;
  }
  @include mq(u-sp) {
    padding-bottom: 160px;
  }
}
//kv
.p-home-kv__scroll__wrap {
  position: absolute;
  @include mq(o-sp) {
    right: 50px;
    bottom: 14px;
  } 
  @include mq(u-sp) {
    top: 50%;
    right: 4%;//13px;
    transform: translateY(-50%);
  }
}
.p-home-kv__scroll__img {
  background-image: url(#{$bgPath}common/img_scroll.png);
  background-size: contain;
  background-repeat: no-repeat;
  @include mq(o-sp) {
    width: 17px;
    height: 87px;
  }
  @include mq(u-sp) {
    width: 11px;
    height: 72px;
  }
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

// swiper
.p-home-swiper {
  position: relative;
  background-color: $colorMain;
  @include mq(o-sp) {
    // max-width: 1600px;
    min-width: 1280px;
  }
}
.p-home-swiper__wrap {
  position: relative;
  @include mq(o-sp) {
    max-width: 1600px;
    min-width: 1280px;
    width: 100%;
    margin: 0 auto;
    height: 540px;
  }
  @include mq(u-sp) {
    height: 438px;
  }
}
.p-home-swiper__container {
  position: absolute;
  top: 0;
  left: 0;
  @include mq(o-sp) {
    width: 1000px;
  }
  @include mq(u-sp) {
    width: 87.5%;
    // width: 280px;
  }
}
.p-home-swiper__wrapper {

}
.p-home-swiper__pagination {
  position: absolute;
  .swiper-pagination-bullet {
    width: 23px;
    height: 1px;
    background: rgba(34,34,34,0.7);
    border-radius: 0;
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    background: #222;
  }
  @include mq(o-sp) {
    bottom: -35px;
    right: 122px;
  }
  @include mq(u-sp) {
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.p-home-swiper__heading {
  @extend .u-ff-NotoR;
  position: absolute;
  z-index: 1;
  @include mq(o-sp) {
    top: 50%;
    right: 95px;
    transform: translateY(-50%);
    color: $colorWhite;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 4px;
    text-shadow: 0 2px 7px rgba($color: #515151, $alpha: .3);
  }
  @include mq(u-sp) {
    color: $colorBlack;
    bottom: 18px;
    left: 12px;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 2.8px;
  }
}
.p-home-swiper__heading__row {
  // @include mq(o-sp) {
  //   &:first-child {
  //     padding: 6px 0 0 9px;
  //   }
  //   &:last-child {
  //     padding: 0 0 6px 9px;
  //   }
  // }
  @include mq(u-sp) {
    padding-left: 6px;
    background-color: $colorWhite;
    &:first-child {
      display: inline-block;
    }
  }
}


//heading
.p-home-heading__section {
  @include mq(o-sp) {
    display: flex;
    width: 1280px;
    margin: 0 auto;
  }
  @include mq(u-sp) {
    margin-top: 80px;
    padding: 0 18px;
  }
}
.p-home-heading__heading__wrap {
  @include mq(o-sp) {
    padding: 193.5px 0 193.5px 94px;
  }
}
.p-home-heading__heading {
  @extend .u-fs-42-24, .u-ff-NotoR;
  letter-spacing: 1px;
  @include mq(u-sp) {
    line-height: 34px;
  }
}
.p-home-heading__translation {
  @extend .u-fs-15-12, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 11px;
    padding-left: 3px;
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    margin-top: 8px;
    letter-spacing: 0.63px;
  }
}
.p-home-heading__text__wrap {
  @include mq(o-sp) {
    padding: 139.5px 99px 139.5px 68px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
  }
}
.p-home-heading__text {
  @extend .u-fs-15-14, .u-ff-NotoR;
  @include mq(o-sp) {
    width: 330px;
    line-height: 34px;
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    line-height: 2;
    letter-spacing: 1.4px;

  }
}

// service
.p-home-service__section {
  @include mq(o-sp) {
    width: 1280px;
    margin: 0 auto;
    &:nth-child(n+4) {
      margin-top: 150px;
    }
  }
  @include mq(u-sp) {
    margin-top: 102px;
    &:nth-child(n+4) {
      margin-top: 50px;
    }
  }
}
.p-home-service__link {
  @include mq(o-sp) {
    transition: .8s all;
    display: flex;
  }
  &:hover {
    opacity: .8;
    .p-home-service__img {
      transform: scale(1.05);
    }
    .p-home-service__viewMore__wrap {
      background-color: #eee;
    }
  }
}
.p-home-service__img__wrap {
  overflow: hidden;
  flex-shrink: 0;
  @include mq(o-sp) {
  }
}
.p-home-service__img {
  @include mq(o-sp) {
    transition: .8s all;
    width: 750px;
  }
}
.p-home-service__box {
  @include mq(o-sp) {
    width: 100%;
  }
  @include mq(u-sp) {
    display: flex;
  }
}
.p-home-service__text__wrap {
  color: $colorWhite;
  @include mq(o-sp) {
    padding: 65px 0 71px;
    text-align: center;
  }
  @include mq(u-sp) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    height: 171px;
    padding: 24px 19px;
  }
}
.p-home-service__heading {
  @extend .u-fs-32-18, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 2.2px;
  } 
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 1.24px;
    text-align: center;
  }
}
.p-home-service__translation {
  @extend .u-fs-16-12, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 6px;
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    margin-top: 2px;
    line-height: 17px;
    letter-spacing: 0.63px;
    text-align: center;
  }
}
.p-home-service__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 38px;
    line-height: 24px;
    letter-spacing: 1.2px;
  }
  @include mq(u-sp) {
    margin-top: 12px;
    line-height: 19px;
    letter-spacing: 1.4px;
  }
}
.p-home-service__viewMore__wrap {
  transition: .8s all;
  @include mq(u-sp) {
    display: flex;
    align-items: center;
    width: 25%;
    padding: 0 10px;
  }
}
.p-home-service__viewMore {
  display: inline-block;
  width: 100%;
  text-align: center;
  @include mq(o-sp) {
    padding: 138px 0;
  }
}
.p-home-service__viewMore__arrow {
  @extend .u-fs-14-10, .u-ff-NotoR;
  &::after {
    content: "";
      display: inline-block;
      background-image: url(#{$bgPath}home/img_arrow.png);
      background-repeat: no-repeat;
  }
  @include mq(o-sp) {
    &:after {
      width: 41px;
      height: 8px;
      margin-left: 28.5px;
      background-size: cover;
    }
  }
  @include mq(u-sp) {
    display: block;
    line-height: 17px;
    letter-spacing: 2.18px;
    &:after {
      width: 37px;
      height: 8px;
      margin-top: 10px;
      background-size: contain;
    }
  }
}

// member
.p-home-member {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 1080px;
    margin: 150px auto 0;
  }
  @include mq(u-sp) {
    margin-top: 70px;
  }
}
.p-home-member__title__wrap {
  @include mq(o-sp) {
    width: 260px;
  }
  @include mq(u-sp) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px
  }
}
.p-home-member__heading__wrap {
}
.p-home-member__heading {
  @extend .u-fs-28-24,.u-ff-NotoEL;
  @include mq(o-sp) {
    letter-spacing: 2.5px;
  }
  @include mq(u-sp) {
    line-height: 35px;
  }
}
.p-home-member__translation {
  @extend .u-fs-14-12, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 6px;
    padding-left: 3px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    line-height: 17px;
    letter-spacing: 3px;
  }
}
.p-home-member__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 18px;
    padding-left: 2px;
    line-height: 24px;
    letter-spacing: 1.6px;
  }
  @include mq(u-sp) {
    margin-top: 42px;
    text-align: center;
  }
}
.p-home-member__link__wrap {
  @include mq(o-sp) {
    margin-top: 20px;
    padding-left: 2px;
  }
}
.p-home-member__link {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.4px;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-left: 10px;
    background-image: url(#{$bgPath}common/icon_dots.png);
    background-size: cover;
  }
}
.p-home-member__contain {
  @include mq(u-sp) {
    margin-top: 28px;
  }
}
.p-home-member__list {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 720px;
  }
}
.p-home-member__listItem {
  @include mq(o-sp) {
    width: 214px;
  }
}
.p-home-member__listItem__text__wrap {
  @include mq(o-sp) {
    margin-top: 6px;
  }
}
.p-home-member__listItem__qualification {
  @extend .u-fs-12-11, .u-ff-NotoR;
  @include mq(u-sp) {
    margin-top: 6px;
  }
}
.p-home-member__listItem__name {
  @extend .u-fs-18-16, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 2px;
    letter-spacing: 1.8px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
  }
}




.p-eventForm{
  margin-bottom: 100px;
}
.p-eventInfo{
  margin-bottom: 50px;
  >div{
    display: flex;
    padding: 20px;
    border: 1px solid #aaa;
    figure{
      width: 30%;
      background-color: #aaa;
      margin-right: 50px;
    }
    div{
      width: 70%;
      padding: 10px;
      h3{
        font-size: 24px;
        margin-bottom: 10px;
      }
      dl{
        display: flex;
        flex-wrap: wrap;
        dd{
          width: 50%;
        }
      }
    }
  }
}
.p-eventForm__form{
  border: 1px solid #aaa;
  padding: 40px 50px;
  margin-bottom: 100px;
  dl{
    display: flex;
    dt{
      font-size: 20px;
      width: 40%;
    }
    dd{
      width: 60%;
    }
  }
  dd{
    margin-bottom: 20px;
    padding: 10px;
  }
  dl dd{
    input,textarea{
      padding: 10px;
      border: 1px solid #aaa;
      width: 100%;
      box-shadow: border-box;
    }
    input:focus,textarea:focus{
      border: 2px solid #455fd6 !important;
    }
  }
  dl:nth-child(2) dd, dl:nth-child(3) dd{
    input{
      padding: auto;
      width: auto;
    }
  }
  dl:last-child{
    dd{
      height: 150px;
    }
    margin-bottom: 50px;
  }
}
.p-eventConfirm__content{
  dl{
    margin-bottom: 20px;
    dt{
      margin-bottom: 5px;
    }
    dd{
      padding: 10px;
      border: 1px solid #aaa;
    }
  }
  dl:last-child{
    margin-bottom: 50px;
  }
}

.p-homeIntermediate__img{
  height: 130px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.p-eventInfo__img{
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.p-single__info--free{
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 10px;
}










.p-companyConfirm{
  dl{
    margin-bottom: 20px;
    dt{
      margin-bottom: 5px;
    }
    dd{
      padding: 10px;
      border: 1px solid #aaa;
    }
  }
  dl:last-child{
    margin-bottom: 50px;
  }
}
//p-plan
.p-planList{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  dl{
    cursor: pointer;
    width: 50%;
    border: 1px solid #aaa;
    padding: 10px;
  }
}
/*
=================サンプル=================*/

// price
.p-home-price {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 1080px;
    margin: 150px auto 0;
  }
  @include mq(u-sp) {
    margin-top: 70px;
  }
}
.p-home-price__title__wrap {
  @include mq(o-sp) {
    width: 260px;
  }
  @include mq(u-sp) {
    padding: 0 20px
  }
}
.p-home-price__heading {
  @extend .u-fs-28-24,.u-ff-NotoEL;
  @include mq(o-sp) {
    letter-spacing: 2.5px;
  }
  @include mq(u-sp) {
    line-height: 35px;
  }
}
.p-home-price__translation {
  @extend .u-fs-14-12, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 6px;
    padding-left: 3px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    line-height: 17px;
    letter-spacing: 3px;
  }
}
.p-home-price__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 18px;
    padding-left: 2px;
    line-height: 24px;
    letter-spacing: 1.6px;
  }
  @include mq(u-sp) {
    margin-top: 42px;
    text-align: center;
  }
}
.p-home-price__link__wrap {
  @include mq(o-sp) {
    margin-top: 20px;
    padding-left: 2px;
  }
  @include mq(u-sp) {
    margin-top: 24px;
  }
}
.p-home-price__link {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.4px;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-left: 10px;
    background-image: url(#{$bgPath}common/icon_dots.png);
    background-size: cover;
  }
}
.p-home-price__contain {
  @include mq(o-sp) {
    width: 798px;
  }
  @include mq(u-sp) {
    margin-top: 28px;
    padding: 0 20px;
  }
}
.p-home-price__img__wrap {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
  }
}
.p-home-price__img {
  width: 250px;
  @include mq(u-sp) {
    width: 100%;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
}
.p-home-price__description {
  @extend .u-ff-NotoR;
  margin-top: 24px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 1.6px;

}
