.p-single {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 1080px;
    margin: 120px auto 0;
    padding-bottom: 250px;
  }
  @include mq(u-sp) {
    padding: 30px 20px 142px;
  }
}
.p-single__content {
  @include mq(o-sp) {
    width: 655px;
  }
}
.p-single__content__img {
  position: relative;
  @include mq(o-sp) {
    width: 655px;
  }
}
.p-single__content__tag {
  @extend .u-fs-24-16, .u-ff-NotoR;
  display: inline-block;
  position: absolute;
  color: #fff;
  text-align: center;
  z-index: 1;
  @include mq(o-sp) {
    top: 10px;
    left: 10px;
    width: 105px;
    padding: 4px 6px;
  }
  @include mq(u-sp) {
    top: 5px;
    left: 5px;
    width: 70px;
    padding: 3px 6px;
  }
}
.p-single__content__category {
  @extend .u-fs-12, .u-ff-NotoR;
  width: 100%;
  margin-top: 15px;
  // text-align: center;
  color: $colorWhite;
}
.p-single__content__category__wrap {
  word-break: keep-all;
}
.p-single__content__category__tag {
  display: inline-block;
  padding: 1px 8px;
  @include mq(u-sp) {
    &:nth-child(n+3) {
      margin-top: 4px;
    }
  }
}
.p-single__content__title {
  @extend .u-fs-21-18, .u-ff-NotoR;
  margin-top: 10px;
  @include mq(o-sp) {
    line-height: 32px;
  }
  @include mq(u-sp) {
    line-height: 22px;
  }
}
.p-single__content__items {
  border-top: 1px solid #EEEEEE;
  @include mq(o-sp) {
    margin-top: 9px;
    padding: 16px 0;
  }
  @include mq(u-sp) {
    margin-top: 12px;
    padding: 10px 0;
  }
}
.p-single__content__item {
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-top: 42px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
.p-single__content__item__heading {
  @extend .u-fs-18-16, .u-ff-NotoR;
  color: $colorMain;
  @include mq(o-sp) {
    letter-spacing: 1.8px;
  }
  @include mq(u-sp) {
    letter-spacing: 1.6px;
  }
}
.p-single__content__item__text {
  @extend .u-fs-14, .u-ff-NotoR;
  margin-top: 5px;
  letter-spacing: 1.4px;
}
.p-single__content__item__text--add {
  color: $colorThinGray;
}
.p-single__content__item__link {
  @extend .u-fs-12, .u-ff-NotoR;
  color: $colorThinGray;
  text-decoration: underline;
}
.p-single__content__btn {
  @include mq(o-sp) {
    width: 250px;
    margin: 99px auto;
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 50px auto 0;
  }
}
