// breakpoint
$breakSP: 420;
$breakPC: 950;
$breakpoints: (
  'u-sp': 'print, screen and (max-width: #{$breakSP}px)',
  'o-sp': 'print, screen and (min-width: #{$breakSP + 1}px)',
  'sp-pc': 'print, screen and (max-width: #{$breakPC}px) and (min-width: #{$breakSP + 1}px)',
  'o-pc': 'print, screen and (min-width: #{$breakPC + 1}px)',
) !default;
@mixin mq($breakpoint) {
  @media #{inspect(map-get($breakpoints, $breakpoint))} {
    @content;
  }
}

// 横並びにリストを配置
// $n：1列の個数、$mr：右マージン、$mb：下マージン
// 例：@include u-boxes(3,40px,40px); 
@mixin u-boxes($n, $mt, $ml) {
  display: flex;
  flex-wrap: wrap;
  >*{
    $space: $ml * ($n - 1);
    // width: calc( (100%/#{$n}) - (#{$space}/#{$n}) );
    margin: $mt 0 0 $ml;
    &:nth-child(#{$n}n - #{$n -  1}){
      margin-left: 0;
    }
    &:nth-child(-n+#{$n}){
      margin-top: 0;
    }
  }
}

// ie用
@mixin ie {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}


// PATH
$bgPath: '../images/';


// COLOR
$colorLight: #3CABE0;
$colorNormal: #2496CD;
$colorDark: #012C45;
$colorBlack: #222;
$colorGray: #636363;
$colorThinGray: #777;
$colorWhite: #fff;
$colorRed: #EC7C79;
$colorMain: #88BFBF;//#6D949E;
$colorStyle: #84B9CB;//#81AFBB;
$colorPersonal: #88B5D3;//#808BA9;
$colorTotal: #8AB1A9;
$colorFooter: #22383E;
$colorBorderStraight: #FBC9C9;
$colorBorderWave: #D1E3ED;
$colorBorderNatural: #D7E9D4;
$colorBorderMeritB: #C9EBF4;
$colorBorderMeritP: #E2C9F4;
$colorBorderMeritG: #D1EDE1;
$colorBorderName: #C0D7DD;
$colorStep01: #88BFBF;
$colorStep02: #84B9CB;
$colorStep03: #88B5D3;
$colorStep04: #8D93C8;
$colorBtn: $colorMain;
$colorBg: #F7F7F7;
$colorEventOpen: #81AFBB;
$colorEventClose: #636363;
$colorTagGood: #7598CA;
$colorTagBad: #DD6559;
$colorBorder: #EBEFF0;
