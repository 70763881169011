.p-faq {
  background-color: $colorBg;
  padding-bottom: 60px;
}
.p-faq__title__wrap {
  background-color: $colorMain;
  padding-bottom: 50px;
  @include mq(u-sp) {
    padding: 17px 0 46px;
  }
}
.p-faq__title {
  @extend .u-fs-46-28,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 1.22px;
  }
}
.p-faq__title__translation {
  @extend .u-fs-18-14,.u-ff-NotoR;
  margin-top: 7px;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.78px;
  }
}
.p-faq__contents {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 980px;
    margin: 0 auto;
    padding:150px 0 300px;
  }
  @include mq(u-sp) {
    padding: 32px 20px 132px;
  }
}
.p-faq__menu__wrap {
  @include mq(o-sp) {
    width: 200px;
  }
}
.p-faq__menu__heading {
  @include mq(u-sp) {
    position: relative;
    padding: 15px 20px;
    background-color: $colorWhite;
    color: $colorMain;
    line-height: 20px;
    letter-spacing: 0.44px;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%) rotate(90deg);
      width: 6px;
      height: 10px;
      background-image: url(#{$bgPath}common/icon_arrow.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.is-active {
      background-color: $colorStyle;
      color: $colorWhite;
      &::after {
        transform: translateY(-50%) rotate(-90deg);
        background-image: url(#{$bgPath}common/icon_arrow-active.png);
      }
    }
  }
}
.p-faq__menu__list {
  @include mq(u-sp) {
    height: 0;
    border: 1px solid $colorStyle;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    &.is-active {
      max-height: 9999px;
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}
.p-faq__menu__listItem {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    position: relative;
    padding: 15px 20px;
    background-color: $colorWhite;
    color: $colorMain;
    line-height: 20px;
    letter-spacing: 0.44px;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 6px;
      height: 10px;
      background-image: url(#{$bgPath}common/icon_arrow.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.is-active {
      background-color: $colorStyle;
      color: $colorWhite;
      &::after {
        background-image: url(#{$bgPath}common/icon_arrow-active.png);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  @include mq(u-sp) {
    padding: 10px 18px;
    border: 1px solid $colorBorder;
    background-color: $colorWhite;
    color: $colorMain;
  }
}
.p-faq__main__wrap {
  @include mq(u-sp) {
    margin-top: 22px;
  }
}
.p-faq__content {
  display: none;
  background-color: $colorWhite;
  &.is-active {
    display: block;
  }
  @include mq(o-sp) {
    width: 750px;
    padding: 24px 20px 5px;
  }
  @include mq(u-sp) {
    padding: 0 20px;
  }
}
.p-faq__content__heading {
  @extend .u-fs-21-18,.u-ff-NotoR;
  color: $colorMain;
  @include mq(o-sp) {
    padding-left: 10px;
  }
  @include mq(u-sp) {
    padding: 15px 10px;
  }
}
.p-faq__content__item {
  @extend .u-fs-14,.u-ff-NotoR;
  border-top: 1px solid $colorBorder;
  line-height: 20px;
  @include mq(o-sp) {
    &:nth-child(2) {
      margin-top: 18px;
    }
  }
  @include mq(o-sp) {
    letter-spacing: 0.8px;
  }
}
.p-faq__content__item__heading {
  position: relative;
  cursor: pointer;
  letter-spacing: 0.47px;
  &::before {
    content: "Q";
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 18px;
    line-height: 18px;
    color: $colorMain;
  }
  @include mq(o-sp) {
    padding: 18px 42px 18px 48px;
  }
  @include mq(u-sp) {
    padding: 20px 42px 0 34px;
    &::before {
      top: 20px;
    }
  }
}
.p-faq__content__item__text__wrap {
  position: relative;
  height: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s;
  &.is-active {
    padding-top: 16px;
    max-height: 999px;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }
  &::before {
    content: "A";
    position: absolute;
    left: 10px;
    font-size: 18px;
    line-height: 18px;
    color: $colorRed;
    @include mq(o-sp) {
      top: 0;
    }
    @include mq(u-sp) {
      top: 16px;
    }
  }
  @include mq(o-sp) {
    letter-spacing: 0.47px;
    &.is-active {
      padding: 0 42px 18px 48px;
    }
  }
  @include mq(u-sp) {
    padding: 0 42px 20px 34px;
  }
}
.p-faq__content__item__text {
  a {
    color: #81afbb;
  }
}
.p-faq__content__item__btn {
  position: absolute;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 14px;
    height: 1;
    border: 1px solid $colorMain;
  }
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 14px;
    height: 1px;
    border: 1px solid $colorMain;
    transform: rotate(90deg);
  }
  &.is-active {
    &::after {
      transform: rotate(0);
    }
  }
  @include mq(o-sp) {
    top: 26px;
    right: 30px;
  }
  @include mq(u-sp) {
    top: 30px;
    right: 24px;
  }
}