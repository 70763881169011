.c-corporation__section{
  margin: 161px 100px 0;

  @include mq(u-sp) {
    margin: 41px 20px 0;
  }
}

.c-corporation {
  background: linear-gradient(81.04deg, #F2F3F6 0%, #F4F5F9 100%);
  @include mq(o-sp) {
    min-width: 1080px;
    padding: 22px 0 0;
  }
  @include mq(u-sp) {
    padding: 40px 20px;
  }
}
.c-corporation__flex {
  @include mq(o-sp) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1080px;
    margin: 0 auto;
  }
}
.c-corporation__img {
  @include mq(o-sp) {
    width: 198px;
  }
  @include mq(u-sp) {
    display: none;
  }
}
.c-corporation__contents__wrap {
  @include mq(o-sp) {
    width: 1080px;
    padding-left: 164px;
  }
}
.c-corporation__heading {
  @extend .u-fs-28-12 , .u-ff-NotoEL;
  @include mq(o-sp) {
    letter-spacing: 2.8px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 1.8px;
    text-align: center;
  }
}
.c-corporation__text {
  @extend .u-fs-16-14, .u-ff-NotoR;
  @include mq(o-sp) {
    line-height: 27px;
    letter-spacing: 1.37px;
  }
  @include mq(u-sp) {
    line-height: 25px;
    letter-spacing: 1.17px;
  }
}
.c-corporation__btn {
  text-align: center;
  @include mq(o-sp) {
    width: 250px;
    margin-top: 32px;
  }
  @include mq(u-sp) {
    width: 100%;
    margin-top: 16px;
  }
}
