.l-header{
  // background-color: $colorMain;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 20;
  @include mq(o-sp) {
    min-width: 1280px;
  }
  @include mq(u-sp) {
    padding: 13px 20px 13px 20px;
  }
}
.l-header__inner {
  display: flex;
  align-items: center;
  @include mq(o-sp) {
    max-width: 1600px;
    margin: 0 auto;
    padding: 27px 50px 27px 36px;
    color: #fff;
  }
  @include mq(u-sp) {
    justify-content: space-between;
  }
}
.l-header__logo {
  @include mq(o-sp) {
    width: 320px;
  }
  @include mq(u-sp) {
    width: 227px;
  }
}
.l-header__hum {
  @include mq(u-sp) {
    width: 20px;
    cursor: pointer;
    span {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $colorWhite;
      &:not(:first-child) {
        margin-top: 7px;
      }
    }
    &.is-active {
      position: relative;
      span {
        position: absolute;
        margin: unset;
        &:first-child {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
        &:nth-child(2) {
          width: 0;
        }
        &:last-child {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
}
.l-header__list__wrap {
  @include mq(o-sp) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 0 auto;
  }
  @include mq(u-sp) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: calc(100vh - 67px);
    padding: 35px 69px 0;
    background: rgba($colorMain, .95);
    z-index: 15;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }
}
.l-header__list {
  @include mq(o-sp) {
    display: flex;
  }
}
.l-header__listItem {
  @extend .u-fs-14-15,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.4px;
    &:nth-of-type(n+2){
      margin-left: 40px;
    }
  }
  @include mq(u-sp) {
    color: $colorWhite;
    &:not(:first-child) {
      border-top: 1px solid rgba($colorWhite,.3);
    }
    >a {
      display: inline-block;
      width: 100%;
      padding: 18px 0;
    }
  }
}
.l-header__list__button {
  @extend .u-ff-NotoR;;
  background-color: #009E9F;
  color: $colorWhite;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 1.2px;
  text-align: center;
  > a {
    display: block;
    padding: 11px 23px;
  }
  @include mq(o-sp) {
    margin-left: 16px;
  }
}
