.c-btn {
  @extend .u-fs-14-12, .u-ff-NotoR;
  display: inline-block;
  width: 100%;
  letter-spacing: 1.4px;
  color: $colorWhite;
  background-color: $colorBtn;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 41px;
    height: 8px;
    margin-left: 29.5px;
    background-image: url(#{$bgPath}common/img_arrow.png);
    background-size: contain;
  }
  @include mq(o-sp) {
    padding: 14.5px 37.5px 14.5px 39px;
  }
  @include mq(u-sp) {
    padding: 11px 25px 11px 25px;
    &:after {
      margin-left: 19.5px;
    }
  }
}
.c-btn--nomal {
  @extend .u-fs-14-12, .u-ff-NotoR;
  display: inline-block;
  width: 100%;
  letter-spacing: 1.4px;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
  @include mq(o-sp) {
    padding: 14.5px 39px;
  }
  @include mq(u-sp) {
    padding: 11px 25px;
  }
}
.c-btn--close {
  @extend .u-fs-14-12, .u-ff-NotoR;
  display: inline-block;
  width: 100%;
  letter-spacing: 1.4px;
  color: $colorBlack;
  background-color: #EAEAEA;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
  @include mq(o-sp) {
    padding: 14.5px 37.5px 14.5px 39px;
  }
  @include mq(u-sp) {
    padding: 11px 25px 11px 25px;
  }
}
.c-btn__color--main {
  color: $colorWhite;
  background-color: $colorBtn;
}