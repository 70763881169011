.p-eventForm{
  input[type="radio"]{
    display: inline;
  }
}

.p-pulldownMenu{
  width: 500px;
  margin: 0 auto;
  border: 1px solid #333;
  margin-bottom: 50px;
  select{
  padding: 30px;
  width: 100%;
  }
}
