.c-event {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 1080px;
    margin: 150px auto 0;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.c-event__title__wrap {
  @include mq(u-sp) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.c-event__heading__wrap {
}
.c-event__heading {
  @extend .u-fs-28-24,.u-ff-NotoEL;
  @include mq(o-sp) {
    letter-spacing: 2.5px;
  }
  @include mq(u-sp) {
    line-height: 35px;
  }
}
.c-event__translation {
  @extend .u-fs-14-12, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 6px;
    padding-left: 3px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    line-height: 17px;
    letter-spacing: 3px;
  }
}
.c-event__link__wrap {
  @include mq(o-sp) {
    margin-top: 38px;
    padding-left: 2px;
  }
}
.c-event__link {
  @extend .u-fs-14, .u-ff-NotoR;
  letter-spacing: 1.4px;
  &:after {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-left: 10px;
    background-image: url(#{$bgPath}common/icon_dots.png);
    background-size: cover;
  }
}
.c-event__list {
  @include mq(o-sp) {
    width: 850px;
    @include u-boxes(3,30px,50px);
  }
  @include mq(u-sp) {
    margin-top: 37px;
  }
}
.c-event__listItem {
  position: relative;
  @include mq(o-sp) {
    width: 250px;
  }
  @include mq(u-sp) {
    width: 100%;
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  > a {
    display: block;
  }
}
.c-event__listItem__img {
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mq(o-sp) {
    height: 150px;
  }
  @include mq(u-sp) {
    height: 168px;
  }
}
.c-event__listItem__category {
  @extend .u-fs-12, .u-ff-NotoR;
  width: 100%;
  margin-top: 10px;
  // text-align: center;
  color: $colorWhite;
}
.c-event__listItem__category__wrap {
  word-break: keep-all;
}
.c-event__listItem__tag {
  display: inline-block;
  padding: 1px 8px;
  &:nth-child(n+3) {
    margin-top: 4px;
  }
}
.c-event__listItem__title {
  @extend .u-fs-16, .u-ff-NotoR;
  margin-top: 4px;

}
.c-event__listItem__days {
  @extend .u-fs-14, .u-ff-NotoR;
  margin-top: 4px;
}
.c-event__listItem__venue {
  @extend .u-fs-14, .u-ff-NotoR;
  margin-top: 2px
}
.c-event__listItem__heading {
  display: inline-block;
  width: 74px;
  color: $colorMain;
}


// side表示用
.c-event--side {
  @include mq(u-sp) {
    margin-top: 70px;
  }
}
.c-event__heading--side {
  @extend .u-fs-18,.u-ff-NotoR;
  line-height: 26px;
  letter-spacing: 1.8px;
}
.c-event__list--side {
  margin-top: 15px;
  padding-top: 19px;
  border-top: 1px solid $colorBorder;
  @include mq(o-sp) {
    width: 327px;
  }
}
.c-event__listItem--side {
  position: relative;
  @include mq(o-sp) {
    width: 327px;
    &:not(:first-child) {
      margin-top: 50px;
    }
  }
  @include mq(u-sp) {
    width: 100%;
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  > a {
    display: block;
  }
}
.c-event__listItem__img--side {
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mq(o-sp) {
    height: 196px;
  }
  @include mq(u-sp) {
    height: 168px;
  }
}

//pagination

.pagination {
  width: 100%;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 50px;
  }
  @include mq(u-sp) {
    margin-top: 30px;
  }
}
.page-numbers{
  @extend .u-fs-14,.u-ff-NotoR;
  display: inline-flex;
  line-height: 28px;
  li {
    box-sizing: content-box;
    width: 28px;
    border: 1px solid $colorStyle;
    &:not(:first-child) {
      margin-left: 20px;
    }
    >a {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: $colorStyle;
      text-align: center;
    }
  }
  &.current {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: $colorStyle;
    color: $colorWhite;
    text-align: center;
  }
  &.dots {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: $colorStyle;
    text-align: center;
  }
}