.p-service__inner {
  @extend .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 140px;
  }
  @include mq(u-sp) {
    margin-top: 40px;
    padding: 0 20px;
  }
}
.p-service-contents {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 80px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
.p-service-contents__img {
  width: 560px;
  @include mq(u-sp) {
    width: 100%;
  }
}
.p-service-contents__area {
  width: 440px;
  @include mq(u-sp) {
    width: 100%;
    margin-top: 16px;
  }
}
.p-service-contents__area__heading {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.1em;
  @include mq(u-sp) {
    font-weight: bold;
  }
}
.p-service-contents__area__description {
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  @include mq(u-sp) {
    margin-top: 8px;
  }
}
.p-service-contents__area__price {
  margin-top: 12px;
  padding: 24px;
  border: 1px solid $colorMain;
  @include mq(u-sp) {
    margin-top: 16px;
    padding: 16px;
  }
}
.p-service-contents__area__price__heading {
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.1em;
  @include mq(u-sp) {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }
}
.p-service-contents__area__price__inner {
  margin-top: 8px;
}
.p-service-contents__area__price__dl--large {
  &:not(:first-child) {
    margin-top: 24px;
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  > dt {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.1em;
    > span {
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.1em;
    }
    @include mq(u-sp) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  > dd {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.1em;
    font-weight: bold;
    @include mq(o-sp) {
      margin-top: 8px;
    }
  }
}
.p-service-contents__area__price__dl--small {
  &:not(:first-child) {
    margin-top: 8px;
  }
  > dt {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
  }
  > dd {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.1em;
    font-weight: bold;
    @include mq(u-sp) {
      margin-top: 4px;
    }
  }
}
.p-service-contents__area__price__dd__tax {
  color: $colorThinGray;
  font-family: "NotoSCJKR";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.1em;
  @include mq(u-sp) {
    font-size: 14px;
    line-height: 20px;
  }
}
.p-service-contents__area__price__dd__text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  font-weight: initial;
  @include mq(u-sp) {
    font-size: 12px;
    line-height: 17px;
  }
}
.p-service__text__wrap {
  margin-top: 24px;
  @include mq(u-sp) {
    margin-top: 10px;
  }
}
.p-service__text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  &:not(:first-child) {
    margin-top: 8px;
  }
  @include mq(u-sp) {
    font-size: 12px;
    line-height: 17px;
  }
}
