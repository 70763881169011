.p-member {
  @include mq(o-sp) {
    width: 1080px;
    margin: 0 auto;
    padding-bottom: 250px;
  }
  @include mq(u-sp) {
    padding-bottom: 170px;
  }
}
.p-member__title__wrap {
  background-color: $colorMain;
  @include mq(o-sp) {
    padding-bottom: 60px;
  }
  @include mq(u-sp) {
    padding: 17px 0 46px;
  }
}
.p-member__title {
  @extend .u-fs-46-28,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 1.22px;
  }
}
.p-member__title__translation {
  @extend .u-fs-18-14,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  margin-top: 7px;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.78px;
  }
}
.p-member-senior {
  @include mq(o-sp) {
    margin-top: 140px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-member-senior__heading {
  @extend .u-fs-28-24,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
    line-height: 40px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 35px;
    letter-spacing: 0.75px;
  }
}
.p-member-senior__translation {
  @extend .u-fs-14,.u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 6px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
    text-align: center;
  }
}
.p-member-senior__list {
  margin-top: 50px;
}
.p-member-senior__listItem {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 50px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 36px;
    }
  }
}
.p-member-senior__listItem__img {
  @include mq(o-sp) {
    width: 252px;
  }
}
.p-member-senior__listItem__contents {
  @include mq(o-sp) {
    width: 740px;
  }
}
.p-member-senior__listItem__belong {
  @extend .u-fs-14,.u-ff-NotoR;
  color: $colorMain;
  letter-spacing: 1.4px;
  @include mq(u-sp) {
    margin-top: 15px;
  }
}
.p-member-senior__listItem__name__wrap {
  display: flex;
  @include mq(o-sp) {
    margin-top: 4px;
  }
  @include mq(u-sp) {
    justify-content: space-between;
    margin-top: 9px;
  }
}
.p-member-senior__listItem__name {
  @extend .u-fs-24-18, .u-ff-NotoR;;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 1.8px;
  }
}
.p-member-senior__listItem__snsLink__wrap {
  display: flex;
  align-items: center;
  @include mq(o-sp) {
    margin-left: 40px;
  }
}
.p-member-senior__listItem__snsLink {
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.p-member-senior__listItem__snsLink--facebook {
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url('#{$bgPath}/member/icon_facebook.png');
  background-size: cover;
}
.p-member-senior__listItem__snsLink--twitter {
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url('#{$bgPath}/member/icon_twitter.png');
  background-size: cover;
}
.p-member-senior__listItem__snsLink--instagram {
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url('#{$bgPath}/member/icon_instagram.png');
  background-size: cover;
}
.p-member-senior__listItem__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 7px;
    line-height: 25px;
    letter-spacing: 1.2px;
  }
  @include mq(u-sp) {
    margin-top: 12px;
    line-height: 24px;
    letter-spacing: 1.4px;
  }
  a {
    color: $colorMain;
  }
}
.p-member-senior__listItem__qualification {
  @extend .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 16px;
  }
  @include mq(u-sp) {
    margin-top: 20px;
  }
}
.p-member-senior__listItem__qualification__heading {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorMain;
  letter-spacing: 1.5px;
}
.p-member-senior__listItem__qualification__wrap {
  @include mq(o-sp) {
    margin-top: 7px;
  }
  @include mq(u-sp) {
    margin-top: 9px;
  }
}
.p-member-senior__listItem__qualification__text {
  @extend .u-fs-14, .u-ff-NotoR;
  padding-left: 1em;
  text-indent: -1em;
  letter-spacing: 1.5px;
  &::before {
    content: "・";
    color: $colorMain;
  }
  &:not(:first-child) {
    margin-top: 8px;
  }
}


.p-member-fashion {
  @include mq(o-sp) {
    padding-top: 150px;
  }
  @include mq(u-sp) {
    margin-top: 66px;
    padding: 0 20px;
  }
}
.p-member-fashion__heading {
  @extend .u-fs-28-24,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
    line-height: 40px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 35px;
    letter-spacing: 0.75px;
  }
}
.p-member-fashion__translation {
  @extend .u-fs-14,.u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 6px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
    text-align: center;
  }
}
.p-member-fashion__contents {
  @include mq(u-sp) {
    margin-top: 46px;
  }
}
.p-member-fashion__content {
  @include mq(o-sp) {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 50px;
    }
  }
}
.p-member-fashion__content__img {
  @include mq(o-sp) {
    width: 170px;
  }
  @include mq(u-sp) {
    width: 100%;
  }
}
.p-member-fashion__content__list {
  @include mq(o-sp) {
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    width: 860px;
    margin-left: 50px;
  }
}
.p-member-fashion__content__listItem {
  @extend .u-fs-16-14, .u-ff-NotoR;
  display: flex;
  align-items: center;
  @include mq(o-sp) {
    width: 33%;
    line-height: 23px;
    letter-spacing: 1.6px;
    &:nth-child(n+4) {
      margin-top: 12px;
    }
  }
  @include mq(u-sp) {
    padding: 10px 0;
    border-bottom: 1px solid $colorBorder;
    letter-spacing: 1.4px;
  }
}
.p-member-fashion__content__listItem__belong {
  color: $colorMain;
  @include mq(u-sp) {
    display: inline-block;
    width: 140px;
  }
}
.p-member-fashion__content__listItem__name {
  @include mq(o-sp) {
    margin-left: 16px;
  }
}

// book
.p-member-book {
  @include mq(o-sp) {
    padding-top: 150px;
  }
  @include mq(u-sp) {
    margin-top: 66px;
    padding: 0 20px;
  }
}
.p-member-book__heading {
  @extend .u-fs-28-24,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
    line-height: 40px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 35px;
    letter-spacing: 0.75px;
  }
}
.p-member-book__translation {
  @extend .u-fs-14,.u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 6px;
  }
  @include mq(u-sp) {
    text-align: center;
    margin-top: 4px;
  }
}
.p-member-book__content {
  margin-top: 47px;
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
  }
}
.p-member-book__content__img {
  width: 170px;
  @include mq(u-sp) {
    margin: 0 auto;
  }
}
.p-member-book__content__wrap {
  @include mq(o-sp) {
    width: 842px;
  }
  @include mq(u-sp) {
    margin-top: 20px;
  }
}
.p-member-book__content__heading {
  @extend .u-fs-21-18, .u-ff-NotoR;
  @include mq(o-sp) {
    line-height: 30px;
    letter-spacing: 1.75px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 1.5px;
  }
}
.p-member-book__author {
  @extend .u-fs-12,.u-ff-NotoR;
  color: $colorThinGray;
}
.p-member-book__text {
  @extend .u-fs-14,.u-ff-NotoR;
  line-height: 25px;
  letter-spacing: 1.2px;
  @include mq(o-sp) {
    margin-top: 14px;
  }
  @include mq(u-sp) {
    margin-top: 17px;
  }
}
.p-member-book__price {
  @extend .u-fs-14,.u-ff-NotoR;
  margin-top: 8px;
  color: $colorThinGray;
}
.p-member-book__content__btn {
  position: relative;
  width: 250px;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 58px;
    transform: translateY(-50%);
    height: 11px;
    width: 11px;
    background-image: url(#{$bgPath}member/icon_link.png);
    background-size: cover;
  }
  > a {
    padding-left: 61px;
    text-align: unset;
  }
  @include mq(o-sp) {
    margin: 50px 0 0 auto;
  }
  @include mq(u-sp) {
    margin: 40px auto 0;
  }
}
