.u-hidden { display: none !important; }
.u-hidden-sp {
  @include mq(u-sp) {
    display: none !important;
  }
}
.u-hidden-pc {
  @include mq(o-sp) {
    display: none !important;
  }
}

//active
.u-eventTag {
  @extend .u-fs-14, .u-ff-NotoR;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  padding: 2px 6px;
  width: 60px;
  color: #fff;
  text-align: center;
}
.u-eventTag--open {
  background-color: $colorEventOpen;
}
.u-eventTag--close {
  background-color: $colorEventClose;
}
.u-isSelectPlan{
  background-color: #F05F60;
  color: #fff;
}
.u-forcusSuccess{
  border: 2px solid $colorMain !important;
}
.u-focusNone{
  border: none !important;
}
.u-forcusError{
  border: 2px solid #F05F60 !important;
}
.u-forcusError--txt{
  display: none;
}
.u-forcusError + .u-forcusError--txt{
  display: block;
  color: #F05F60;
}
.u-eventClose:hover{
  opacity: 1 !important;
}
.u-flexReverse-row {
  flex-direction: row-reverse;
}
.u-overflow-hidden {
  overflow: hidden !important;
}

.u-bg-color--step01 {
  background-color: $colorStep01;
}
.u-bg-color--step02 {
  background-color: $colorStep02;
}
.u-bg-color--step03 {
  background-color: $colorStep03;
}
.u-bg-color--step04 {
  background-color: $colorStep04;
}
.u-bg-color--step01--color{
  background-color: #808BA9;
}
.u-bg-color--step02--color {
  background-color: #9380A9;
}
.u-bg-color--step03--color {
  background-color: #A9808E;
}
.u-bg-color--step04--color {
  background-color: #A98280;
}
.u-bg-color--step01--total {
  background-color: #8AB1A9;
}
.u-bg-color--step02--total {
  background-color: #8AA4B1;
}
.u-bg-color--step03--total {
  background-color: #8A8FB1;
}
.u-bg-color--step04--total {
  background-color: #A18AB1;
}
.u-flex-align-items--start {
  align-items: flex-start;
}

//font-family
.u-ff-NotoR {
  font-family: "NotoSCJKR";
}
.u-ff-NotoEL {
  font-family: "NotoSCJKEL";
}


//font-size
.u-fs-10 {
  font-size: 10px;
}
.u-fs-12{
  font-size: 12px;
}
.u-fs-12-11 {
  @include mq(o-sp) {
    font-size: 12px;
  }
  @include mq(u-sp) {
    font-size: 11px;
  }
}
.u-fs-12-14 {
  @include mq(o-sp) {
    font-size: 12px;
  }
  @include mq(u-sp) {
    font-size: 14px;
  }
}
.u-fs-14 {
  font-size: 14px;
}
.u-fs-14-10 {
  @include mq(o-sp) {
    font-size: 14px;
  }
  @include mq(u-sp) {
    font-size: 10px;
  }
}
.u-fs-14-12{
  @include mq(o-sp) {
    font-size: 14px;
  }
  @include mq(u-sp) {
    font-size: 12px;
  }
}
.u-fs-14-15{
  @include mq(o-sp) {
    font-size: 14px;
  }
  @include mq(u-sp) {
    font-size: 15px;
  }
}
.u-fs-15-12 {
  @include mq(o-sp) {
    font-size: 15px;
  }
  @include mq(u-sp) {
    font-size: 12px;
  }
}
.u-fs-15-14 {
  @include mq(o-sp) {
    font-size: 15px;
  }
  @include mq(u-sp) {
    font-size: 14px;
  }
}
.u-fs-16 {
  font-size: 16px;
}
.u-fs-16-12 {
  @include mq(o-sp) {
    font-size: 16px;
  }
  @include mq(u-sp) {
    font-size: 12px;
  }
}
.u-fs-16-14 {
  @include mq(o-sp) {
    font-size: 16px;
  }
  @include mq(u-sp) {
    font-size: 14px;
  }
}
.u-fs-17-14 {
  @include mq(o-sp) {
    font-size: 17px;
  }
  @include mq(u-sp) {
    font-size: 14px;
  }
}
.u-fs-18 {
  font-size: 16px;
}
.u-fs-18-14 {
  @include mq(o-sp) {
    font-size: 18px;
  }
  @include mq(u-sp) {
    font-size: 14px;
  }
}
.u-fs-18-16 {
  @include mq(o-sp) {
    font-size: 18px;
  }
  @include mq(u-sp) {
    font-size: 16px;
  }
}
.u-fs-20{
  font-size: 20px;
}
.u-fs-20-18{
  @include mq(o-sp) {
    font-size: 20px;
  }
  @include mq(u-sp) {
    font-size: 18px
  }
}
.u-fs-21-14{
  @include mq(o-sp) {
    font-size: 21px;
  }
  @include mq(u-sp) {
    font-size: 14px
  }
}
.u-fs-21-16{
  @include mq(o-sp) {
    font-size: 21px;
  }
  @include mq(u-sp) {
    font-size: 16px
  }
}
.u-fs-21-18{
  @include mq(o-sp) {
    font-size: 21px;
  }
  @include mq(u-sp) {
    font-size: 18px
  }
}
.u-fs-24-16{
  @include mq(o-sp) {
    font-size: 24px;
  }
  @include mq(u-sp) {
    font-size: 16px
  }
}
.u-fs-24-18{
  @include mq(o-sp) {
    font-size: 24px;
  }
  @include mq(u-sp) {
    font-size: 18px
  }
}
.u-fs-24-21{
  @include mq(o-sp) {
    font-size: 24px;
  }
  @include mq(u-sp) {
    font-size: 21px
  }
}
.u-fs-28-24{
  @include mq(o-sp) {
    font-size: 28px;
  }
  @include mq(u-sp) {
    font-size: 24px;
  }
}
.u-fs-28-18{
  @include mq(o-sp) {
    font-size: 28px;
  }
  @include mq(u-sp) {
    font-size: 18px;
  }
}
.u-fs-28-12{
  @include mq(o-sp) {
    font-size: 28px;
  }
  @include mq(u-sp) {
    font-size: 12px;
  }
}
.u-fs-32-18 {
  @include mq(o-sp) {
    font-size: 32px;
  }
  @include mq(u-sp) {
    font-size: 18px;
  }
}
.u-fs-42-24 {
  @include mq(o-sp) {
    font-size: 42px;
  }
  @include mq(u-sp) {
    font-size: 24px;
  }
}
.u-fs-46-28 {
  @include mq(o-sp) {
    font-size: 46px;
  }
  @include mq(u-sp) {
    font-size: 28px;
  }
}

//margin
.u-mt-30 { margin-top: 30px !important; }
.u-mt-4 { margin-top: 4px !important; }
.u-mt-24to15 {
  @include mq(o-sp) {
    margin-top: 24px !important;
  }
  @include mq(u-sp) {
    margin-top: 15px !important;
  }
}
//background-color 

.u-bg-style { background-color: $colorStyle; }
.u-bg-personal { background-color: $colorPersonal; }
.u-bg-total { background-color: $colorTotal; }

a {
  transition: .4s;
  &:hover {
    opacity: .6;
    cursor: pointer;
  }
}

.u-color-total {
  color: $colorTotal;
}
.u-color-total--before {
  &::before {
    color: $colorTotal;
  }
}
.u-color-personal {
  color: $colorPersonal;
}
.u-color-personal--before {
  &::before {
    color: $colorPersonal;
  }
}
