.l-footer {
  background-color: $colorFooter;
  @include mq(o-sp) {
    min-width: 1280px;
    padding: 39px 0 27.5px;
  }
  @include mq(u-sp) {
    padding: 36px 20px 8px;
  }
}
.l-footer__wrap {
  @include mq(o-sp) {
    width: 1080px;
    margin: 0 auto;
  }
}
.l-footer__flex {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}
.l-footer__menu__wrap {
  @include mq(o-sp) {
  }
}
.l-footer__menu__list {
  color: $colorWhite;
  @include mq(o-sp) {
    display: flex;
    &:not(:first-child) {
      margin-top: 14px;
    }
  }
}
.l-footer__menu__listItem {
  @extend .u-fs-12,.u-ff-NotoR;
  letter-spacing: 1.2px;
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-left: 50px;
    }
  }
  @include mq(u-sp) {
    border-bottom: 1px solid #405155;
    >a {
      display: inline-block;
      width: 100%;
      padding: 12px 0;
    }
  }
  
}
.l-footer__logo {
  @include mq(o-sp) {
    width: 370px;
  }
  @include mq(u-sp) {
    width: 231px;
    margin: 45px auto 0;
  }
}
.l-footer__copylight__wrap {
  width: 100%;
  @include mq(o-sp) {
    margin-top: 54.5px;
  }
}
.l-footer__copylight {
  @extend .u-fs-10,.u-ff-NotoR;
  color: rgba(#FFF, 0.5);
  @include mq(o-sp) {

  }
  @include mq(u-sp) {
    margin-top: 35px;
    text-align: center;
  }
}

.l-footer__pageTop__wrap {
  position: absolute;
  @include mq(o-sp) {
    right: 50px;
    bottom: 50px;
  } 
  @include mq(u-sp) {
    right: 11px;
    bottom: 44px;
  }
}
.l-footer__pageTop__img {
  background-image: url(#{$bgPath}common/img_pageTop.png);
  background-size: contain;
  background-repeat: no-repeat;
  @include mq(o-sp) {
    width: 17px;
    height: 87px;
  }
  @include mq(u-sp) {
    width: 11px;
    height: 72px;
  }
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}