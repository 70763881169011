.p-event__title__wrap {
  background-color: $colorMain;
  padding-bottom: 60px;
  @include mq(u-sp) {
    padding: 17px 0 46px;
  }
}
.p-event__title {
  @extend .u-fs-46-28,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 1.22px;
  }
}
.p-event__title__translation {
  @extend .u-fs-18-14,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  margin-top: 7px;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.78px;
  }
}
.p-event__contents {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    width: 1080px;
    margin: 150px auto;
  }
  @include mq(u-sp) {
    padding:60px 0 130px;
  }
}
.p-event__content__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    padding: 0 20px;
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-event__content__list {
  margin-top: 10px;
}
.p-event__content__listItem {
  border-top: 1px solid $colorBorder;
  transition: .3s ;
  &:last-child {
    border-bottom: 1px solid $colorBorder;
  }
  &:hover {
    background-color: rgba(#6D949E,0.05);
  }
  @include mq(o-sp) {
    width: 800px;
  }
}
.p-event__content__listItem__img {
  position: relative;
  display: block;
  background-size: cover;
  @include mq(o-sp) {
    flex-shrink: 0;
    width: 300px;
    height: 180px;
  }
  @include mq(u-sp) {
    width: 100%;
    height: 180px;
  }
}
.p-event__content__listItem__link {
  @include mq(o-sp) {
    display: flex;
    padding: 50px 30px;
  }
  @include mq(u-sp) {
    display: inline-block;
    width: 100%;
    padding: 20px;
  }
}
.p-event__content__listItem__content {
  @include mq(o-sp) {
    margin-left: 50px;
  }
}
.p-event__content__listItem__category {
  @extend .u-fs-12, .u-ff-NotoR;
  width: 100%;
  margin-top: 10px;
  // text-align: center;
  color: $colorWhite;
}
.p-event__content__listItem__category__wrap {
  word-break: keep-all;
}
.p-event__content__listItem__tag {
  display: inline-block;
  padding: 1px 8px;
  @include mq(u-sp) {
    &:nth-child(n+3) {
      margin-top: 4px;
    }
  }
}
.p-event__content__listItem__title {
  @extend .u-fs-21-16, .u-ff-NotoR;
  margin-top: 10px;

}
.p-event__content__listItem__days {
  @extend .u-fs-16-14, .u-ff-NotoR;
  margin-top: 4px;
  @include mq(o-sp) {
    letter-spacing: 0.78px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.67px;
  }
}
.p-event__content__listItem__venue {
  @extend .u-fs-16-14, .u-ff-NotoR;
  margin-top: 4px;
  @include mq(o-sp) {
    letter-spacing: 0.78px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.67px;
  }
}
.p-event__content__listItem__heading {
  @extend .u-ff-NotoR;
  display: inline-block;
  color: $colorMain;
  @include mq(o-sp) {
    width: 100px;
  }
  @include mq(u-sp) {
    width: 74px;
  }
}

.p-event__btn {
  @include mq(o-sp) {
    width: 250px;
    margin: 50px auto 0;
  }
  @include mq(u-sp) {
    width: 250px;
    margin: 30px auto 0;
  }
}

//sidebar
.p-event__sideBar__wrap {
  @include mq(o-sp) {
    width: 200px;
  }
  @include mq(u-sp) {
    margin-top: 73px;
    padding: 0 20px;
  }
}
.p-event__sideBar {
  &:not(:first-child) {
    margin-top: 22px;
  }
}
.p-event__sideBar__heading {
  @extend .u-fs-18,.u-ff-NotoR;
  line-height: 26px;
  letter-spacing: 1.8px;
}
.p-event__sideBar__list {
  margin-top: 15px;
}
.p-event__sideBar__listItem {
  @extend .u-fs-14, .u-ff-NotoR;
  border-top: 1px solid $colorBorder;
  letter-spacing: 1.4px;
  color: $colorMain;
  &:last-child {
    border-bottom: 1px solid $colorBorder;
  }
  a {
    display: inline-block;
    width: 100%;
    padding: 15px 0;
  }
}
.p-event__sideBar__archive__text {
  @extend .u-fs-12,.u-ff-NotoR;
  position: relative;
  margin-top: 15px;
  padding: 11px 12px;
  border: 1px solid $colorBtn;
  color: $colorMain;
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(90deg);
    width: 6px;
    height: 10px;
    background-image: url(#{$bgPath}common/icon_arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.is-active {
    background-color: $colorStyle;
    color: $colorWhite;
    &::after {
      transform: translateY(-50%) rotate(-90deg);
      background-image: url(#{$bgPath}common/icon_arrow-active.png);
    }
  }
}
.p-event__sideBar__archive__list {
  height: 0px;
  visibility: hidden;
  opacity: 0;
  transition: .5s;
  &.is-active {
    max-height: 9999px;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }
  li {
    @extend .u-fs-14, .u-ff-NotoR;
    border-top: 1px solid $colorBorder;
    letter-spacing: 1.4px;
    color: $colorMain;
    &:last-child {
      border-bottom: 1px solid $colorBorder;
    }
    a {
      display: inline-block;
      width: 100%;
      padding: 15px 0;
    }
  }
}
.p-event__sideBar__archive__listItem {

}
.p-eventArvhive__content {
  &:not(:first-child) {
    margin-top: 100px;
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 50px;
    }
  }
}
// news
.p-eventNews__item {
  @extend .u-fs-14, .u-ff-NotoR;
  border-top: 1px solid $colorBorder;
  line-height: 20px;
  @include mq(o-sp) {
    width: 800px;
    letter-spacing: 0.8px;
    &:first-of-type {
      margin-top: 8px;
    }
  }
  @include mq(u-sp) {
    margin: 0 20px;
    &:first-of-type {
      margin-top: 12px;
    }
  }
}
.p-eventNews__item__heading {
  position: relative;
  cursor: pointer;
  letter-spacing: 0.47px;
  @include mq(o-sp) {
    padding: 18px 42px 18px 10px;
  }
  @include mq(u-sp) {
    padding: 10px 42px 10px 5px;
    &::before {
      top: 20px;
    }
  }
}
.p-eventNews__item__heading__date {
  @extend .u-fs-12,.u-ff-NotoR;

  color: $colorThinGray;
  margin-right: 16px;
  @include mq(u-sp) {
    display: block;
  }
}
.p-eventNews__item__text__wrap {
  position: relative;
  height: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s;
  &.is-active {
    padding-top: 16px;
    max-height: 999px;
    height: 100%;
    visibility: visible;
    opacity: 1;
    @include mq(u-sp) {
      padding: 0 24px 20px 5px;
    }
  }
  @include mq(o-sp) {
    letter-spacing: 0.47px;
    &.is-active {
      padding: 0 42px 18px 10px;
    }
  }

}
.p-eventNews__item__text {
  a {
    color: #81afbb;
  }
}
.p-eventNews__item__btn {
  position: absolute;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 14px;
    height: 1;
    border: 1px solid $colorMain;
  }
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 14px;
    height: 1px;
    border: 1px solid $colorMain;
    transform: rotate(90deg);
  }
  &.is-active {
    &::after {
      transform: rotate(0);
    }
  }
  @include mq(o-sp) {
    top: 26px;
    right: 30px;
  }
  @include mq(u-sp) {
    top: 30px;
    right: 24px;
  }
}