@font-face {
  font-family: "AbhayaLibre";
  font-weight: 400;
  src: url("../fonts/AbhayaLibre-Regular.woff");
}
@font-face {
  font-family: "NotoSCJKR";
  font-weight: 400;
  src: url("../fonts/NotoSerifCJKjp-Regular.woff");
}
@font-face {
  font-family: "NotoSCJKEL";
  font-weight: 400;
  src: url("../fonts/NotoSerifCJKjp-ExtraLight.woff");
}
@font-face {
  font-family: "NotoSCJKR";
  font-weight: bold;
  src: url("../fonts/NotoSerifCJKjp-Bold.woff");
}
@font-face {
  font-family: "NotoSCJKR";
  font-weight: 600;
  src: url("../fonts/NotoSerifCJKjp-SemiBold.woff");
}
body {
  line-height: 1.5;
  //  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;  /* 游ゴシック系 */
  //  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;  /* 游明朝系 */
   font-family: "ヒラギノ角ゴ ProN W3",Hiragino Sans,"ヒラギノ角ゴシック",Hiragino Kaku Gothic ProN, Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;  /* ヒラギノ角ゴ系 */
}
