.c-border {
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 8px;
    z-index: -1;
  }
  @include mq(u-sp) {
    &::after {
      height: 5px;
      bottom: 2px;
    }
  }
}
.c-border--member {
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 8px;
    z-index: -1;
  }
  @include mq(u-sp) {
    &::after {
      height: 5px;
      bottom: -2px;
    }
  }
}
.c-border-straight {
  &::after {
    background-color: $colorBorderStraight;
  }
}
.c-border-wave {
  &::after {
    background-color: $colorBorderWave;
  }
}
.c-border-natural {
  &::after {
    background-color: $colorBorderNatural;
  }
}
.c-border-meritB {
  &::after {
    background-color: $colorBorderMeritB;
  }
}
.c-border-meritP {
  &::after {
    background-color: $colorBorderMeritP;
  }
}
.c-border-meritG {
  &::after {
    background-color: $colorBorderMeritG;
  }
}
.c-border-member {
  &::after {
    background-color: $colorBorderName;
  }
}