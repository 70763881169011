.p-totalcounseling {
  @include mq(o-sp) {
    width: 1080px;
    margin: 0 auto;
    padding-bottom: 250px;
  }
  @include mq(u-sp) {
    padding-bottom: 150px
  }
}
// kv
.p-totalCounseling-kv {
  background-color: $colorTotal;
}
.p-totalCounseling-kv__wrap {
  @include mq(o-sp) {
    position: relative;
    max-width: 1600px;
    min-width: 1280px;
    width: 100%;
    margin: 0 auto;
    height: 540px;
  }
}
.p-totalCounseling-kv__img {
  @include mq(o-sp) {
    position: absolute;
    top: 0;
    right: 0;
    width: 1000px;
  }
}
.p-totalCounseling-kv__text__wrap {
  @extend .u-ff-NotoR;
  color: $colorWhite;
  @include mq(o-sp) {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    z-index: 1;
  }
  @include mq(u-sp) {
    padding: 26px 20px 37px;
  }
}
.p-totalCounseling-kv__heading {
  @extend .u-fs-46-28, .u-ff-NotoR;
  text-shadow: 0 2px 7px rgba($color: #515151, $alpha: .3);
  @include mq(o-sp) {
    line-height: 67px;
    letter-spacing: 4px;
  }
  @include mq(u-sp) {
    line-height: 35px;
    letter-spacing: 1.22px;
  }
}
.p-totalCounseling-kv__text {
  @extend .u-fs-17-14, .u-ff-NotoR;
  text-shadow: 0 2px 7px rgba($color: #515151, $alpha: .3);
  @include mq(o-sp) {
    margin-top: 19px;
    line-height: 27px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    margin-top: 14px;
    line-height: 22px;
    letter-spacing: 1px;
  }
}

// description
.p-totalCounseling-description {
  @include mq(o-sp) {
    margin-top: 150px;
  }
  @include mq(u-sp) {
    margin-top: 73px;
    padding: 0 20px;
  }
}
.p-totalCounseling-description__text__wrap {
  @include mq(o-sp) {
    padding-top: 74px;
  }
}
.p-totalCounseling-description__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.5px;
  }
}
.p-totalCounseling-description__text {
  a {
    color: $colorTotal;
  }
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    width: 650px;
    margin: 26px auto 0;
    line-height: 25px;
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
    line-height: 24px;
    letter-spacing: 1.4px;
  }
}


// merit
.p-totalCounseling-merit {
  @include mq(o-sp) {
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-totalCounseling-merit__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-totalCounseling-merit__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
  }
}
.p-totalCounseling-merit__contents {
  @include mq(o-sp) {
    margin-top: 41px;
  }
  @include mq(u-sp) {
    margin-top: 35px;
  }
}
.p-totalCounseling-merit__content__wrap {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 49px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.p-totalCounseling-merit__content {
  @include mq(o-sp) {
    width: 510px;
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
.p-totalCounseling-merit__content__heading {
  @extend .u-fs-21-18,.u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    line-height: 18px;
    letter-spacing: 0.86px;
  }
}
.p-totalCounseling-merit__content__text {
  @extend .u-fs-14, .u-ff-NotoR;
  letter-spacing: 1px;
  @include mq(o-sp) {
    margin-top: 14px;
  }
  @include mq(u-sp) {
    margin-top: 18px;
  }
}

// flow
.p-totalCounseling-flow {
  @include mq(o-sp) {
    margin-top: 161px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-totalCounseling-flow__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1.47px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: 1px;
  }
}
.p-totalCounseling-flow__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  margin-top: 5px;
  color: $colorThinGray;
  text-align: center;
  letter-spacing: 1px;
}
.p-totalCounseling-flow__blocks {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
  }
  @include mq(u-sp) {
    margin-top: 36px;
  }
}
.p-totalCounseling-flow__block {
  color: $colorWhite;
  @include mq(o-sp) {
    width: 247px;
    padding: 39px 26px 24px;
  }
  @include mq(u-sp) {
    padding: 23px 19px 28px;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}
.p-totalCounseling-flow__block__step {
  @extend .u-fs-20-18, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 1px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 0.6px;
  }
}
.p-totalCounseling-flow__block__heading {
  @extend .u-fs-24-21, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    margin-top: 7px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}
.p-totalCounseling-flow__block__text {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    margin-top: 17px;
    padding-left: 5px;
    letter-spacing: 1.08px;
  }
  @include mq(u-sp) {
    margin-top: 10px;
    padding: 0 10px;
    letter-spacing: 1px;
  }
}

// menu
.p-totalCounseling-menu {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    margin-top: 140px;
  }
  @include mq(u-sp) {
    margin-top: 70px;
    padding: 0 20px;
  }
}
.p-totalCounseling-menu__heading__wrap {
  @include mq(o-sp) {
    width: 322px;
  }
}
.p-totalCounseling-menu__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  @include mq(o-sp) {
    letter-spacing: 2.5px;
  }
  @include mq(u-sp) {
    text-align: center;
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-totalCounseling-menu__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  @include mq(o-sp) {
    margin-top: 6px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    text-align: center;
    margin-top: 5px;
    letter-spacing: 1px;
  }
}
.p-totalCounseling-menu__text {
  @extend .u-fs-14, .u-ff-NotoR;
  line-height: 24px;
  letter-spacing: 1.4px;
  @include mq(o-sp) {
    margin-top: 17px;
  }
  @include mq(u-sp) {
    margin-top: 30px;
  }
}
.p-totalCounseling-menu__btn {
  >a {
    background-color: #8AB1A9;
  }
  @include mq(o-sp) {
    width: 250px;
    margin-top: 26px;
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 30px auto 0;
  }
}
.p-totalCounseling-menu__contents {
  @include mq(u-sp) {
    margin-top: 26px;
  }
}
.p-totalCounseling-menu__content {
  @extend .u-ff-NotoR;
  background-color: $colorBg;
  &:not(:first-child) {
    margin-top: 10px;
  }
  @include mq(o-sp) {
    width: 680px;
    padding: 28px 32px 26px;
  }
  @include mq(u-sp) {
    padding: 26px 20px 20px;
  }
}
.p-totalCounseling-menu__content__heading {
  text-indent: -1.1em;
  margin-left: 1.1em;
  @extend .u-fs-16, .u-ff-NotoR;
  line-height: 23px;
  letter-spacing: 1.6px;
}
.p-totalCounseling-menu__content__item {
  @extend .u-fs-14, .u-ff-NotoR;
  display: flex;
  line-height: 20px;
  @include mq(o-sp) {
    margin-top: 17px;
    &:nth-child(n+3) {
      margin-top: 18px;
    }
  }
  @include mq(u-sp) {
    margin-top: 21px;
    &:nth-child(n+3) {
      margin-top: 13px;
    }
  }
}
.p-totalCounseling-menu__content__item__heading {
  color: $colorMain;
  @include mq(o-sp) {
    width: 102px;
  }
  @include mq(u-sp) {
    flex-shrink: 0;
    width: 50px;
  }
}
.p-totalCounseling-menu__content__item__text {
  @include mq(u-sp) {
    word-break: break-all;
  }
}
.p-totalCounseling-menu__content__list {
  // text-indent: -24px;
  // margin-left:  6px;
  @include mq(o-sp) {
    margin-top: 24px;
  }
  @include mq(u-sp) {
    margin-top: 14px;
  }
}
.p-totalConseling__menu__content__detail{
  @extend .u-fs-12, .u-mt-4, .u-ff-NotoR;
  color: $colorThinGray;
  line-height: 1.6;
}
.p-totalCounseling-menu__content__listItem {
  a {
    color: $colorTotal;
  }
  @extend .u-fs-12;
  padding-left: 1em;
  text-indent: -1em;
  line-height: 18px;
  color: $colorThinGray;
  &::before {
    content: "・";
    color: $colorMain;
  }
  @include mq(o-sp) {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  @include mq(u-sp) {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}

// simpleDiagnosis
.p-totalCounseling-simpleDiagnosis {
  @include mq(o-sp) {
    padding-top: 120px;
  }
  @include mq(u-sp) {
    margin-top: 50px;
    padding: 0 20px;
  }
}
.p-totalCounseling-simpleDiagnosis__heading {
  @extend .u-fs-28-24, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 0.88px;
  }
  @include mq(u-sp) {
    line-height: 34px;
    letter-spacing: -0.27px;
  }
}
.p-totalCounseling-simpleDiagnosis__translation {
  @extend .u-fs-14, .u-ff-NotoR;
  color: $colorThinGray;
  letter-spacing: 1px;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 5px;
  }
  @include mq(u-sp) {
    margin-top: 4px;
  }
}
.p-totalCounseling-simpleDiagnosis__content {
  margin-top: 47px;
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
  }
}
.p-totalCounseling-simpleDiagnosis__content__img {
  width: 177px;
  @include mq(u-sp) {
    margin: 0 auto;
  }

}
.p-totalCounseling-simpleDiagnosis__content__wrap {
  @include mq(o-sp) {
    width: 842px;
  }
  @include mq(u-sp) {
    margin-top: 20px;
  }
}
.p-totalCounseling-simpleDiagnosis__content__heading {
  @extend .u-fs-21-18, .u-ff-NotoR;
  @include mq(o-sp) {
    line-height: 30px;
    letter-spacing: 1.75px;
  }
  @include mq(u-sp) {
    line-height: 26px;
    letter-spacing: 1.5px;
  }
}
.p-totalCounseling-simpleDiagnosis__text {
  @extend .u-fs-14,.u-ff-NotoR;
  line-height: 25px;
  letter-spacing: 1.2px;
  @include mq(o-sp) {
    margin-top: 14px;
  }
  @include mq(u-sp) {
    margin-top: 17px;
  }
}
.p-totalCounseling-simpleDiagnosis__content__btn {
  position: relative;
  width: 250px;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 58px;
    transform: translateY(-50%);
    height: 11px;
    width: 11px;
    background-image: url(#{$bgPath}member/icon_link.png);
    background-size: cover;
  }
  > a {
    padding-left: 61px;
    text-align: unset;
  }
  @include mq(o-sp) {
    margin: 50px 0 0 auto;
  }
  @include mq(u-sp) {
    margin: 40px auto 0;
  }
}
