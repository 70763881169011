.p-contact {
}
.p-contact__title__wrap {
  background-color: $colorMain;
  padding-bottom: 60px;
  @include mq(u-sp) {
    padding: 17px 0 46px;
  }
}
.p-contact__title {
  @extend .u-fs-46-28,.u-ff-NotoR;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 1.22px;
  }
}
.p-contact__title__translation {
  @extend .u-fs-18-14,.u-ff-NotoR;
  margin-top: 7px;
  color: $colorWhite;
  text-align: center;
  @include mq(o-sp) {
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    letter-spacing: 0.78px;
  }
}
.p-contact__form {
  @include mq(o-sp) {
    width: 810px;
    margin: 150px auto 250px;
  }
  @include mq(u-sp) {
    padding: 50px 20px 142px;
  }
}
.p-contact__form__step__wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @include mq(o-sp) {
    width: 280px;
    margin-bottom: 82px;
  }
  @include mq(u-sp) {
    width: 185px
  }
}
.p-contact__form__step__img {
  @include mq(o-sp) {
    width: 40px;
  }
  @include mq(u-sp) {
    width: 27px;
  }
}
.p-contact__form__text {
  @extend .u-fs-14-12,.u-ff-NotoR;
  line-height: 20px;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 12px;
  }
  @include mq(u-sp) {
    margin-top: 21px;
  }
}
.p-contact__form__symbol--after {
  &::after {
    content: "＊";
    margin-left: 3px;
    color: $colorStyle;
  }
}
.p-contact__form__symbol--before {
  &::before {
    content: "＊";
    margin-right: 3px;
    color: $colorStyle;
  }
}
.p-contact__form__item {
  @extend .u-fs-14, .u-ff-NotoR;
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    line-height: 20px;
  }
  @include mq(u-sp) {
    margin-top: 40px;
    &:nth-child(n+5) {
      margin-top: 20px;
    }
  }
}
.p-contact__form__item__heading {
  @extend .u-ff-NotoR;
  @include mq(o-sp) {
    width: 130px;
  }
}
.p-contact__form__item__content {
  position: relative;
  input,textarea,select{
    width: 100%;
    padding: 8.5px 19px;
    border-radius: 2px;
    background-color: #f6f6f6;
    &::placeholder {
      color: #CBC5C5;
    }
  }
  select {
    @extend .u-ff-NotoR;
  }
  textarea {
    height: 120px;
  }
  @include mq(o-sp) {
    width: 627px;
  }
  @include mq(u-sp) {
    margin-top: 9px;
  }
}
.p-contact__form__item__content__arrow::before {
  content: "";
  width: 10px;
  height: 6px;
  background: url("/wp-content/themes/mytheme/assets/images/common/icon_arrow_select.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 16px;
  right: 16px;
}
.p-contact__form__text {
  @extend .u-fs-12,.u-ff-NotoR;
  line-height: 17px;
  color: #777;
}
.p-contact__form__confirm__text {
  @extend .u-fs-14-12, .u-ff-NotoR;
  margin-top: 48px;
  line-height: 20px;
  text-align: center;
  >a{
    text-decoration: underline;
    color: $colorStyle;
  }
  @include mq(u-sp) {
    margin-top: 40px;
  }
}
.p-contact__form__btn {
  width: 250px;
  margin: 38px 280px 0;
  a {
    &::after {
      margin-left: 24.5px;
    }
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 16px auto 0;
    a {
      &::after {
        margin-left: 14.5px;
      }
    }
  }
}

//confirm
.p-contact__form__item--confirm {
  @extend .u-fs-14, .u-ff-NotoR;
  &:nth-child(8) {
    border-bottom: 1px solid #eee;
  }
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid #eee;
    line-height: 20px;
    &:nth-child(3) {
      margin-top: 40px;
    }
  }
  @include mq(u-sp) {
    padding: 16px 0;
    &:nth-child(n+4) {
      border-top: 1px solid #eee;
    }
  }
}
.p-contact__form__item__heading--confirm {
  @extend .u-ff-NotoR;
  color: #555;
  @include mq(o-sp) {
    width: 130px;
  }
}
.p-contact__form__btn__wrap--confirm {
  @include mq(o-sp) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 520px;
    margin: 50px auto 0;
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 30px auto 0;
  }
}
.p-contact__form__btn--send {
  @include mq(o-sp) {
    width: 250px;
  }
}
.p-contact__form__btn--back {
  color: $colorMain;
  border: 1px solid $colorBtn;
  @include mq(o-sp) {
    width: 250px;
  }
  @include mq(u-sp) {
    margin-top: 15px;
  }
}

//done
.p-contact__form__text--done {
  @extend .u-fs-28-18, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    line-height: 44px;
    letter-spacing: 2px;
  }
  @include mq(u-sp) {
    margin-top: 42px;
    line-height: 29px;
    letter-spacing: 1.29px;
  }
}
.p-contact__form__text__sub--done {
  @extend .u-fs-18-14, .u-ff-NotoR;
  text-align: center;
  @include mq(o-sp) {
    margin-top: 28px;
    line-height: 44px;
    letter-spacing: 1.5px;
  }
  @include mq(u-sp) {
    margin-top: 20px;
    line-height: 20px;
    letter-spacing: 1.17px;
  }
}
.p-contact__form__btn--done {
  @include mq(o-sp) {
    width: 250px;
    margin: 45px auto 0;
  }
  @include mq(u-sp) {
    width: 200px;
    margin: 50px auto 0;
  }
}